.flex{
display: flex;
justify-content: space-evenly;
flex-flow: row wrap;
width: 100%;
}
.flex-image{
    max-width: 40%;
    max-height: 40vh;
    height: fit-content;
    border-radius: 6px;

}
.card-fullWidth-image{
max-width: 120%;
margin-left: -10%;
}
.topSection{
display: flex;
flex-flow: row wrap;
justify-content: space-around;
width: 100%;
align-items: flex-end;
}

.topImage{
filter: brightness(0.75);
max-width: 75vw;
}

.sectionHeader{
text-align: center;
}
.sectionHeaderTitle{
padding-left: 1.5em;
padding: 2rem;
font-size: 2.5rem;
font-weight: 700;
}

.sectionHeaderSubtitle{
padding-left: 1.5em;
padding: 2rem;
font-size: 2.5rem;
font-weight: 600;
}

.instantAvailabilitySection{
    padding: 1em;
}

@media only screen and (min-width: 992px) and (max-width: 1999px){
.fitContentCard{
    max-width: 35vw;
}

ion-fab{
margin: 5em;
}

.topImage{
    max-width: 20vw;
}

.flex-image{
    max-width: 30vw;
    max-height: 60vh;
padding: 2em 6em;
}

.homepageContentWrapper{
    --background: url(https://tyotyodata.imfast.io/color-bg.png) no-repeat bottom / cover;
z-index: 0;
}
.homepageContent{
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10vh;
}

.topSection{
display: flex;
flex-flow: row wrap;
justify-content: space-around;
width: 100%;
align-items: flex-end;
}

.sectionHeader{
width: 100%;
padding: 5em;
padding-left: 5em;
}

.sectionHeaderTitle{
padding-left: 5em;
padding: 5rem;
font-size: 3rem;
font-weight: 700;
}

.sectionHeaderSubtitle{
padding-left: 5em;
padding: 5rem;
font-size: 2.5rem;
font-weight: 600;
}


.applyNowCard{
max-width: 45%
}

.featuresSection{
    flex-flow: row wrap;
width: 100%;
display: flex;
justify-content: space-evenly;

}
.miniCard{
max-width: 25vw;
}

.instantAvailabilitySection{
    width: 100%;
  padding: 5em;
padding-top: 0;
padding-left: 5em;
display: flex;
flex-flow: row wrap;
justify-content: space-evenly;
}

.useCasesSection{
display: flex;
flex-flow: row wrap;
justify-content: space-evenly;
}

}

@media only screen and (min-width: 2000px){
.flex-image{
    max-width: 50%;
    max-height: 50vh;
padding: 2em 8em;
}

.sectionHeader{
width: 100%;
padding: 2em;
margin: 2em 0;
}

.sectionHeaderTitle{
font-size: 4rem;
}

ion-fab{
margin: 5em;
}


   .miniCard{
max-width: 15vw;
} 

.fitContentCard{
    max-width: 25vw;
}

.topImage{
    max-width: 20vw;
}

.homepageContentWrapper{
    --background: url(https://tyotyodata.imfast.io/color-bg.png) no-repeat bottom / cover;
z-index: 0;
}
.homepageContent{
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10vh;
}

.topSection{
display: flex;
flex-flow: row wrap;
justify-content: space-around;
width: 100%;
align-items: flex-end;
}

.applyNowCard{
max-width: 45%
}

.featuresSection{
    flex-flow: row wrap;
width: 100%;
display: flex;
justify-content: space-evenly;

}

.instantAvailabilitySection{
    width: 100%;
  padding: 5em;
padding-top: 0;
padding-left: 5em;
display: flex;
flex-flow: row wrap;
justify-content: space-evenly;
}

.useCasesSection{
    width: 100%;
display: flex;
flex-flow: row wrap;
justify-content: space-evenly;
}

}
