body {
	background-color: var(--ion-color-light) !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 992px){
    /*
ion-content{
width: 50vw !important;
left: 25%;
}
*/
}

.my-node-enter {
  opacity: 0;
}
.my-node-appear {
  opacity: 1;
  transition: transform 300ms;
  transform: translateX(100%);
}
.my-node-appear-active{
transform: translateX(0);
}

.my-node-exit {
  opacity: 1;
  transform: translateX(0);
}
.my-node-exit-active {
  transition: transform 300ms;
  transform: translateX(100%);
}
.my-node-exit-done{
  opacity: 0;
  transform: translateX(100%);
}



.my-node-back-enter {
  opacity: 0;
}
.my-node-back-appear {
  opacity: 1;
  transition: transform 300ms;
  transform: translateX(-100%);
}
.my-node-back-appear-active{
transform: translateX(0);
}

.my-node-back-exit {
  opacity: 1;
}
.my-node-back-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
