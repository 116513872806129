.padding-top-p{
padding-top: 1vh;

}
.circle-underlay{
	background-color: var(--ion-color-light);
height: 2.5em;
width: 2.5em;
text-align: center;
	border-radius: 100%;	
}

.circle-overlay-p{
	margin: auto;
line-height: 3em !important;
}

.comment-p{
white-space: normal;
}

.dashboardMainContainer{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
}

.dashboardMainWrapper{
}


.quickInsightsList{
width: fit-content;
}

.quickInsightsList > ion-list-header{
white-space: nowrap;
}

.quickInsightsCardContent{
    display: flex;
}
.quickInsightsCard{
    /*box-shadow: unset;*/
box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
}

.entriesList{
width: fit-content;
max-height: 75vh;
overflow-y: scroll;
padding: 1em;
}

.fitContentCard{
    width: fit-content;
    height: fit-content;
}

.dashboardPage{
}

.dashboardContent{
    /*--background: var(--ion-color-light);*/
}
.dashboardContent{
    --background: var(--ion-color-light);
    --background: url(https://tyotyodata.imfast.io/color-bg.png) no-repeat bottom / cover;
    z-index: 0;
}



.buttonCard{
    /*
background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
background-size: 600%;
*/
         }

.buttonCardShadow{
background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
background-size: 600%;

z-index: -1;
filter: blur(30px);
opacity: 0.8;
position: absolute;
margin-top: -35px;
}

         .alertLogoutButton{
         color: var(--ion-color-danger) !important;
     }

     .editCampaignModal{
     padding-top: 5vh;
     }

     .editCampaignModal .modal-wrapper {
     border-radius: 6px !important;
     border-radius: 6px !important;
     }

     .editCampaignModal .ion-page{
     padding: 1em;
     }

.flatCard{
box-shadow: none;
border: 1px solid var(--ion-color-medium);
background: unset;
}

.blobCard{
    z-index: 5;

}

.blurred{
position: absolute;
z-index: 1;
filter: blur(3em);
}

.blob1{
height: 20em;
top: 0;
left: -37vw;
transform: rotate(-45deg);
}

.blob2{
height: 7.5em;
top: 75%;
left: 0vw;
transform: rotate(45deg);
}

.blob3{
height: 10em;
top: 35%;
right: 5vw;
transform: rotate(135deg);
}

ion-toolbar{
    --background: transparent !important;
}




@media (prefers-color-scheme: dark) {
.blurred{
filter: blur(3em) invert();
}
}



@media only screen and (min-width: 992px){
.requestAccessInput ion-list{
    width: 50%;
margin: auto;
}
.entriesDetailList ion-card{
    width: 50%;
margin: auto;
}

ion-content{
     width: unset !important;
   left: unset;
   -background: transparent !important;
   padding: 5em;
}
ion-toolbar{
    --background: transparent !important;
}
body{
    --background: url(https://tyotyodata.imfast.io/color-bg.png) no-repeat center / cover;
}

.dashboardMainWrapper{
padding: 5em;
}

/*
ion-content .dashboardContent{
  --background: var(--ion-color-light);
    --background: url(https://tyotyodata.imfast.io/color-bg.png) no-repeat center/cover;
}
*/




.button-inner{
    width: 10%;
    height: 5%;
}



}


@media only screen and (min-width: 1923) and (max-width: 5000px){
     .editCampaignModal .modal-wrapper{
         max-height: 50vh;
         max-width: 50vw;
         height: 100%;
         width: 100%;
     }

}



.button-wrapper{
display: flex;
justify-content: center;
align-items: center;
width: fit-content;
}

.button-main{
    border-radius: 6px;
    background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
    background-size: 600%;
    text-align: center;
    color: white;
    height: fit-content;
    width: fit-content;
    display: grid;
}
.button-main ion-card-subtitle{
    color: var(--ion-color-light)
}

.button-main ion-card{
    box-shadow: unset;
    margin: 0;
}

.button-main ion-card-header{
}

.button-inner{
    position: absolute;
    z-index: -1;
    filter: blur(30px);
    opacity: 0.8;
    border-radius: 6px;
    background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
    background-size: 600%;
    text-align: center;
    color: white;
    width: 25%;
    height: 10%;
    margin-top: -10%
}



 @keyframes anime {
   0%{
     background-position: 0% 50%
 }
   50%{
     background-position: 100% 50%
 }
   100%{
     background-position: 0% 50%
 }
 }
